<template>
  <div id="inside">
    <div class="container">
      <div class="row">
        <div class="d-flex align-items-start justify-content-start flex-column px-2 pb-4 col-lg-12">
          <div class="btn-group btn-group-sm ">
            <a href="home.html" class="btn btn-link d-flex flex-column justify-content-center text-secondary"><i class="fa fa-home fa-fw"></i></a>
            <router-link
              to="#"
              ><a href="#" class="btn btn-link text-secondary" style="text-decoration: none; cursor: initial;">Acordos</a>
            </router-link>
          </div>
        </div>
        <div class="col-lg-12">
          <h1> Acordos</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="d-inline-flex flex-wrap flex-column col-lg-4 col-md-12 col-acao">
          <div class="card">
            <div class="card-header" style="" contenteditable="true"><i class="fa fa-fw mr-2 fa-circle text-secondary"></i>Acordos concluídos</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h5 class="">Cartão C</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-6">
                  <p class="m-0 text-info">Valor:</p>
                  <h6 contenteditable="true">R$ 2.000,00</h6>
                  <p class="m-0 text-info">Contrato:</p>
                  <p class="">3312.0012/21</p>
                </div>
                <div class="col-6 col-md-6">
                  <p class="text-info mb-0">Início: 12/09/2021</p>
                  <p class="text-info">Final: 12/11/2021</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p class="m-0 text-info">Descrição:</p>
                  <p>Renegociação de pendências de cartão.</p>
                  <div class="table-responsive">
                    <table class="table table-sm" width="100%" cellspacing="0">
                      <thead class="">
                        <tr class="small">
                          <th>Parcelas</th>
                          <th>Valor</th>
                          <th>Data Pgmto</th>
                        </tr>
                      </thead>
                      <tbody class="text-muted">
                        <tr>
                          <td>1</td>
                          <td>1.000,00</td>
                          <td>12/09/2021</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>500,00</td>
                          <td>12/10/2021</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>500,00</td>
                          <td>12/11/2021</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row">
              </div>
              <div class="row">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "MeusAcordos",
    components: {
    },
    data() {
      return {
        links: [],
      };
    },
    methods: {

    },
    created() {
      this.user.Menus.forEach((menuId) => {
        switch (parseInt(menuId)) {
          case 2:
            this.links.push({
              icon: "",
              text: "Contratos Negociáveis",
              route: "/dashboard/minhas-dividas",
            });
            break;
          case 1:
            this.links.push({
              icon: "",
              text: "Boletos Ativos",
              route: "meusBoletos",
            });
            break;
          case 3:
            this.links.push({
              icon: "",
              text: "Acordos",
              route: "/dashboard/meus-acordos",
            });
            break;
        }
      });
    },
    computed: {
      ...mapState("auth", ["user"]),
      isLoggedIn() {
        return this.$store.getters["auth/isLoggedIn"];
      },
    }
};
</script>